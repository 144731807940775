import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MappingCondition, PersonName } from '@local/generated/graphql'
import { useGetClockInDateText } from './hooks'
import { getFormattedEmail } from './utils'

interface Props {
  mappingStatus: MappingCondition
  userName: PersonName | null
  email: string | null
  mostRecentClockInDate: Date | null
  employeeIsArchived: boolean
}

const GetPosProfileContent = ({
  mappingStatus,
  userName,
  email,
  mostRecentClockInDate,
  employeeIsArchived
}: Props) => {
  const { t } = useTranslation('employees')
  const clockInText = useGetClockInDateText(mostRecentClockInDate)
  const formattedEmail = getFormattedEmail(email)

  if (mappingStatus === 'MAPPED_USER_NOT_MAPPED') {
    return <div className='font-semibold'>{t('profileNotMapped')}</div>
  }

  if (mappingStatus === 'MAPPED_USER_MAPPING_IGNORED') {
    return (
      <>
        <div className='mb-1 font-semibold'>{t('ignoredProfileMapping')}</div>
        {t('ignoredEmployeeDescription')}
      </>
    )
  }

  return (
    <div className='break-normal'>
      <div className='mb-1 font-semibold'>
        {userName?.formatted} {employeeIsArchived ? `(${t('archived')})` : null}
      </div>
      <div>
        {formattedEmail ? (
          <>
            {formattedEmail.username}
            <wbr />@{formattedEmail.domain}
          </>
        ) : (
          t('noEmailOnFile')
        )}
      </div>
      {employeeIsArchived ? (
        <div>{t('unarchivePrompt')}</div>
      ) : (
        <div className='break-normal'>{clockInText}</div>
      )}
    </div>
  )
}

export const PosProfile = (props: Props) => {
  const { t } = useTranslation('employees')

  return (
    <div
      className='self-start break-words justify-self-start'
      data-testid='pos-profile-section'
    >
      <div className='font-semibold type-caption text-gray-50 type-overline'>
        {t('posProfile')}
      </div>
      {GetPosProfileContent(props)}
    </div>
  )
}
