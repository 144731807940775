import { BadgeTitle, DashboardCard } from '@toasttab/buffet-pui-card'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { ExpandIcon, WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  EmployeeAtLocation,
  EmployeeMappingStatus
} from '@local/generated/graphql'
import { PayrollProfile } from './PayrollProfile'
import { MappingStatus } from './MappingStatus'
import { JobCard } from './JobCard'
import { levelIdToString, NOT_MAPPED_STATUSES } from './utils'
import { PosProfileSection } from './PosProfileSection'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

interface Props {
  employee: EmployeeMappingStatus
  location: EmployeeAtLocation
}

export const MappingStatusLocationCard: React.FunctionComponent<
  Props & TestIdentifiable
> = ({ testId = 'mapping-status-location-card', employee, location }) => {
  const [expanded, setExpanded] = useState(true)
  const { t } = useTranslation('employees')
  const status = location.mappingStatus
  const isProfileMapped = location.posRestaurantUserGuid != null

  const getLocationTitle = () => {
    const locationName = t('locationMapping', {
      locationName: location.payrollLocationName
    })
    if (expanded) return locationName

    if (NOT_MAPPED_STATUSES.includes(status)) {
      return (
        <BadgeTitle
          badgeTitle={t('locationNotMappedTitle')}
          color='error'
          textTitle={locationName}
        />
      )
    }

    if (
      status !== 'MAPPED_USER_MAPPED' ||
      location.jobs.some((job) => job.mappingStatus !== 'MAPPED_USER_MAPPED')
    ) {
      return (
        <>
          {locationName}
          <WarningOutlineIcon
            testId={'location-mapping-title-alert'}
            accessibility='decorative'
            className='ml-4 text-error'
          />
        </>
      )
    }

    return locationName
  }

  const CardContent = () => {
    if (!expanded) return <></>
    if (NOT_MAPPED_STATUSES.includes(status)) {
      const errorText = t('locationNotMappedBody')
      return (
        <Alert title='' variant='error'>
          {errorText}
        </Alert>
      )
    }
    const jobs = location.jobs.filter((job) => job.mappingStatus !== 'POS_ONLY')
    return (
      <>
        <div className='grid grid-cols-4'>
          <PayrollProfile
            name={employee.name}
            email={employee.email}
            mostRecentClockInDate={location.posMostRecentClockInDate}
            isMapped={isProfileMapped}
          />
          <div className='place-self-center'>
            <MappingStatus
              status={status}
              isProfileMapped={isProfileMapped}
              forEntity='employee'
            />
          </div>
          {/* put these in their own section so we can cleanly swap between profile view / edit mapping view*/}
          <PosProfileSection
            mappingStatus={location.mappingStatus}
            payrollUserName={employee.name}
            posUserName={location.posUserName}
            email={location.posEmail}
            mostRecentClockInDate={location.posMostRecentClockInDate}
            locationUuid={location.posLocationGuid!}
            posStatus={location.posStatus}
          />
        </div>
        <DividingLine />
        {jobs.map((job, index) => {
          return (
            <div key={levelIdToString(job.payrollId!)}>
              {index > 0 && <DividingLine />}
              <JobCard
                testId={'job-card'}
                job={job}
                payrollEmployeeFirstName={
                  employee.name.chosen ?? employee.name.first
                }
                locationId={location.payrollLocationId!}
                isProfileMapped={isProfileMapped}
              />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className='mb-4' data-testid={testId}>
      <DashboardCard
        cardAction={
          <IconButton
            testId={'expand-collapse-button'}
            icon={
              <ExpandIcon
                className='text-default'
                aria-label={'expand icon'}
                isExpanded={expanded}
              />
            }
            onClick={() => setExpanded(!expanded)}
          />
        }
        cardTitle={getLocationTitle()}
        noPadding={!expanded} // this is a hack to hide the 'empty' card body when minimized
      >
        <CardContent />
      </DashboardCard>
    </div>
  )
}
